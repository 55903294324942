// --- Board ---
import andrewlee from "../images/ourTeam/memberImages/andrewlee.jpg";
import arjunsivakumar from "../images/ourTeam/memberImages/arjunsivakumar.jpg";
import carly from "../images/ourTeam/memberImages/carly.jpg";
import emmyc from "../images/ourTeam/memberImages/emmyc.jpg";
import gayathri from "../images/ourTeam/memberImages/gayathri.jpeg";
import justin from "../images/ourTeam/memberImages/justin.jpg";
import kaila from "../images/ourTeam/memberImages/kailalong.jpeg";
import kainoa from "../images/ourTeam/memberImages/kainoa.jpeg";
import maithyle from "../images/ourTeam/memberImages/maithyle.jpg";
import michellelin from "../images/ourTeam/memberImages/michellelin.jpg";
import noah from "../images/ourTeam/memberImages/noah.png";

// --- Leads ---
import dang from "../images/ourTeam/memberImages/dang.jpg";
import joshlipton from "../images/ourTeam/memberImages/joshlipton.jpg";
import madhu from "../images/ourTeam/memberImages/madhu.jpg";
import michaelpien from "../images/ourTeam/memberImages/michaelpien.png";
import michelleblin from "../images/ourTeam/memberImages/michelleblin.jpg";
import minh from "../images/ourTeam/memberImages/minhnguyen.jpg";
import ostend from "../images/ourTeam/memberImages/ostend.png";
import rafael from "../images/ourTeam/memberImages/rafael.jpg";
import selina from "../images/ourTeam/memberImages/selina.jpg";

// --- Members ---
import lanaramadan from '../images/ourTeam/memberImages/lanaramadan.png';
import kaitlinleung from '../images/ourTeam/memberImages/kaitlinleung.jpg';
import kevinwu from '../images/ourTeam/memberImages/kevinwu.jpg';
import nathanpietrantonio from '../images/ourTeam/memberImages/nathanpietrantonio.jpg';
import seanfong from '../images/ourTeam/memberImages/seanfong.jpg';
import seankelman from '../images/ourTeam/memberImages/seankelman.jpg';
import alyssia from '../images/ourTeam/memberImages/alyssia.jpg';
import zion from '../images/ourTeam/memberImages/zion.jpg';
import ethanho from '../images/ourTeam/memberImages/ethanho.jpg';
import matthewc from '../images/ourTeam/memberImages/matthewc.png';
import stevenzhou from '../images/ourTeam/memberImages/stevenzhou.png';
import cheryl from '../images/ourTeam/memberImages/cheryl.jpg';
import elenakao from '../images/ourTeam/memberImages/elena.jpg';
import rayan from '../images/ourTeam/memberImages/rayan.jpg';
import priskak from '../images/ourTeam/memberImages/priskak.jpg';
import davidgershony from '../images/ourTeam/memberImages/davidgershony.jpg';
import phillipcutter from '../images/ourTeam/memberImages/phillipcutter.jpg';
import shawnliu from '../images/ourTeam/memberImages/shawnliu.jpg';
import chloecheng from '../images/ourTeam/memberImages/chloecheng.jpg';
import katy from "../images/ourTeam/memberImages/katy.jpg";
import kevinliu from "../images/ourTeam/memberImages/kevinliu.png";
import jessie from '../images/ourTeam/memberImages/jessie.jpg';
import lianne from "../images/ourTeam/memberImages/lianne.jpg";
import bobbyz from "../images/ourTeam/memberImages/bobbyz.jpeg";
import kade from '../images/ourTeam/memberImages/kade.jpg';
import kristenyee from '../images/ourTeam/memberImages/kristenyee2.jpg';
import subinkim from '../images/ourTeam/memberImages/subin.jpg';
import farahnaz from '../images/ourTeam/memberImages/farahnaz.jpg';
import bensonmanzano from '../images/ourTeam/memberImages/bensonmanzano.jpg';
import vyvu from '../images/ourTeam/memberImages/vy_vu.jpeg';
import winstonlee from '../images/ourTeam/memberImages/winston_lee.jpeg';
import kayleedoliente from '../images/ourTeam/memberImages/kaylee_doliente.jpeg';
import elisealinsug from '../images/ourTeam/memberImages/elise_alinsug.jpg';
import dianaserrano from '../images/ourTeam/memberImages/diana_serrano.jpg';
import jasminewu from '../images/ourTeam/memberImages/jasmine_wu.jpeg';
import kaitlyntran from '../images/ourTeam/memberImages/kaitlyn_tran.jpeg';
import jessicasotelo from '../images/ourTeam/memberImages/jessica_sotelo.jpeg';
import christinenguyen from '../images/ourTeam/memberImages/christine_nguyen.jpeg';
import philipjian from '../images/ourTeam/memberImages/philip_jian.jpg';
import michellepak from '../images/ourTeam/memberImages/michelle_pak.png';
import brendanlieu from '../images/ourTeam/memberImages/brendan_lieu.jpeg';
import maanpatel from '../images/ourTeam/memberImages/maan_patel.jpg';
import ericliu from '../images/ourTeam/memberImages/eric_liu.jpg';
// --- ------- ---

// Note for bootcamp task: The members attribute of each section is an ordered list.
// You should add your member object after the leads of your respective team,
// in alphabetical order by first name - last name.
const members = {
  board: {
    name: 'Board',
    members: [
      {
        name: 'Michelle Lin',
        position: 'President',
        linkedinURL: 'https://www.linkedin.com/in/michellelinCA',
        imageURL: michellelin,
      },
      {
        name: 'Arjun Sivakumar',
        position: 'Internal VP',
        linkedinURL: 'https://www.linkedin.com/in/arjunsvkmr/',
        imageURL: arjunsivakumar,
      },
      {
        name: 'Noah Arsala',
        position: 'External VP',
        linkedinURL: 'https://www.linkedin.com/in/noah-arsala-0324911b3/',
        imageURL: noah,
      },
      {
        name: 'Andrew Lee',
        position: 'Technical Director',
        linkedinURL: 'https://www.linkedin.com/in/andrew-lee-0641b380/',
        imageURL: andrewlee,
      },
      {
        name: 'Justin Liao',
        position: 'Technical Director',
        linkedinURL: 'https://www.linkedin.com/in/justin-liao-1949b3217/',
        imageURL: justin,
      },
      {
        name: 'Caroline Guzylak',
        position: 'Design Director',
        linkedinURL: 'https://www.linkedin.com/in/cguzylak/',
        imageURL: carly,
      },
      {
        name: 'Gayathri Yedavilli',
        position: 'Marketing Director',
        linkedinURL: 'https://www.linkedin.com/in/gayathri-yedavilli/',
        imageURL: gayathri,
      },
      {
        name: 'Kainoa Nishida',
        position: 'Treasurer',
        linkedinURL: 'https://www.linkedin.com/in/kainoa-nishida-308b68253/',
        imageURL: kainoa,
      },
      {
        name: 'Kaila Long',
        position: 'Membership Director',
        linkedinURL: 'https://www.linkedin.com/in/kaila-long-3952b7210/',
        imageURL: kaila,
      },
      {
        name: 'Maithy Le',
        position: 'Outreach Director',
        linkedinURL: 'https://www.linkedin.com/in/maithy-le',
        imageURL: maithyle,
      },
      {
        name: 'Emmy Chen',
        position: 'Outreach Director',
        linkedinURL: 'https://www.linkedin.com/in/emmy-chen-583552252',
        imageURL: emmyc,
      },
    ],
  },
  aiss: {
    name: "Achievement Institute of STEM Scholars",
    members: [
      {
        name: "Selina Arjomand",
        position: "Design Lead",
        linkedinURL: "https://www.linkedin.com/in/selinaarjomand/",
        imageURL: selina,
      },
      {
        name: "Michael Pien",
        position: "Tech Lead",
        linkedinURL: "https://www.linkedin.com/in/michaelpien/",
        imageURL: michaelpien,
      },
      {
        name: "Michelle Lin",
        position: "Tech Lead",
        linkedinURL: "https://www.linkedin.com/in/michellelin1/",
        imageURL: michelleblin,
      },
      {
        name: 'Elise Alinsug',
        position: 'Designer',
        linkedinURL: 'https://www.linkedin.com/in/elise-alinsug',
        imageURL: elisealinsug,
      },
      {
        name: 'Diane Serrano',
        position: 'Designer',
        linkedinURL: 'https://www.linkedin.com/in/diana-serrano-96502b268',
        imageURL: dianaserrano,
      },
      {
        name: 'Kaila Long',
        position: 'Designer',
        linkedinURL: 'https://www.linkedin.com/in/kaila-long-3952b7210/',
        imageURL: kaila,
      },
      {
        name: "Vy Vu",
        position: "Designer",
        linkedinURL: "https://www.linkedin.com/in/quynhvyvu",
        imageURL: vyvu,
      },
      {
        name: "Alyssia Tan",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/alyssia-tan/",
        imageURL: alyssia,
      },
      {
        name: 'Cheryl Chen',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/cherylhchen/',
        imageURL: cheryl,
      },
      {
        name: 'Chloe Cheng',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/chloehcheng',
        imageURL: chloecheng,
      },
      {
        name: 'Ethan Ho',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/h0ethan04/',
        imageURL: ethanho,
      },
      {
        name: 'Kade Na',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/kadejna/',
        imageURL: kade,
      },
      {
        name: 'Kristen Yee',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/kristencyee/',
        imageURL: kristenyee,
      },
      {
        name: 'Lana Ramadan',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/lanamramadan/',
        imageURL: lanaramadan,
      },
      {
        name: "Lianne Lee",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/lianne-lee-87605b1ab/",
        imageURL: lianne,
      },
      {
        name: 'Maithy Le',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/maithy-le',
        imageURL: maithyle,
      },
      {
        name: "Philip Jian",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/philip-jian/",
        imageURL: philipjian,
      },
      {
        name: "Sean Fong",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/seancfong/",
        imageURL: seanfong,
      },
      {
        name: 'Subin Kim',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/subinleekim/',
        imageURL: subinkim,
      },
    ],
  },
  fph: {
    name: "Feeding Pets of the Homeless",
    members: [
      {
        name: "Minh Nguyen",
        position: "Design Lead",
        linkedinURL: "https://www.linkedin.com/in/minhtnguy/",
        imageURL: minh,
      },
      {
        name: "Josh Lipton",
        position: "Tech Lead",
        linkedinURL: "https://www.linkedin.com/in/joshua-lipton/",
        imageURL: joshlipton,
      },
      {
        name: "Madhu Sharma",
        position: "Tech Lead",
        linkedinURL: "https://www.linkedin.com/in/madhuxsharma/",
        imageURL: madhu,
      },
      {
        name: "Christine Nguyen",
        position: "Designer",
        linkedinURL: "www.linkedin.com/in/christine-nguyen-099621222",
        imageURL: christinenguyen,
      },
      {
        name: "Kaitlyn Tran",
        position: "Designer",
        linkedinURL: "https://www.linkedin.com/in/kaitlyn-tran-888832272/",
        imageURL: kaitlyntran,
      },
      {
        name: "Kaylee Doliente",
        position: "Designer",
        linkedinURL: "https://www.linkedin.com/in/kayleedoliente",
        imageURL: kayleedoliente,
      },
      {
        name: "Michelle Pak",
        position: "Designer",
        linkedinURL: "http://www.linkedin.com/in/michelle-pak-935106251",
        imageURL: michellepak,
      },
      {
        name: 'Benson Manzano',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/benson-manzano/',
        imageURL: bensonmanzano,
      },
      {
        name: 'David Gershony',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/david-gershony/',
        imageURL: davidgershony,
      },
      {
        name: 'Elena Kao',
        position: 'Developer',
        linkedinURL: 'http://www.linkedin.com/in/elena-yi-ting-kao-115b1524b',
        imageURL: elenakao,
      },
      {
        name: 'Eric Liu',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/aircliu',
        imageURL: ericliu,
      },
      {
        name: 'Kainoa Nishida',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/kainoa-nishida-308b68253/',
        imageURL: kainoa,
      },
      {
        name: 'Kaitlin Leung',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/kaitlin-leung-467a35209/',
        imageURL: kaitlinleung,
      },
      {
        name: "Kevin Liu",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/kevinnliu/",
        imageURL: kevinliu,
      },
      {
        name: "Maan Patel",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/maan-patel-b2061a215",
        imageURL: maanpatel,
      },
      {
        name: 'Priska Kunaidy',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/priska-kunaidy-aab508261/',
        imageURL: priskak,
      },
      {
        name: 'Sean Kelman',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/sean-kelman-633057211/',
        imageURL: seankelman,
      },
      {
        name: 'Shawn Liu',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/xiangjian-shawn-liu/',
        imageURL: shawnliu,
      },
      {
        name: "Zion Mateo",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/zionmateo/",
        imageURL: zion,
      },
    ],
  },
  s2t: {
    name: "Stand Up to Trash",
    members: [
      {
        name: "Rafael Muñoz",
        position: "Design Lead",
        linkedinURL: "https://www.linkedin.com/in/rafael-carrillo-mu%C3%B1oz/",
        imageURL: rafael,
      },
      {
        name: "Dang Nguyen",
        position: "Tech Lead",
        linkedinURL: "https://www.linkedin.com/in/nwin/",
        imageURL: dang,
      },
      {
        name: "Ostend Suryajaya",
        position: "Tech Lead",
        linkedinURL: "https://www.linkedin.com/in/ostend-suryajaya/",
        imageURL: ostend,
      },
      {
        name: 'Gayathri Yedavilli',
        position: 'Designer',
        linkedinURL: 'https://www.linkedin.com/in/gayathri-yedavilli/',
        imageURL: gayathri,
      },
      {
        name: 'Jasmine Wu',
        position: 'Designer',
        linkedinURL: 'https://www.linkedin.com/in/jaslavie/',
        imageURL: jasminewu,
      },
      {
        name: 'Jessica (Jay) Sotelo',
        position: 'Designer',
        linkedinURL: 'http://linkedin.com/in/jessica-sotelo',
        imageURL: jessicasotelo,
      },
      {
        name: "Winston Lee",
        position: "Designer",
        linkedinURL: "https://www.linkedin.com/in/winstonylee",
        imageURL: winstonlee,
      },
      {
        name: "Brendan Lieu",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/blieu/",
        imageURL: brendanlieu,
      },
      {
        name: "Bobby Zhong",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/bobby-zhong/",
        imageURL: bobbyz,
      },
      {
        name: 'Emmy Chen',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/emmy-chen-583552252',
        imageURL: emmyc,
      },
      {
        name: 'Farahnaz Hoque',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/farahnazhoque/',
        imageURL: farahnaz,
      },
      {
        name: 'Jessie He',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/hejessie/',
        imageURL: jessie,
      },
      {
        name: "Katy Huang",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/katy-huang-820153223/",
        imageURL: katy,
      },
      {
        name: "Kevin Wu",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/kevinwu098/",
        imageURL: kevinwu,
      },
      {
        name: "Matthew Chang",
        position: "Developer",
        linkedinURL: "http://www.linkedin.com/in/matthew-chang-39754a216",
        imageURL: matthewc,
      },
      {
        name: "Nathan Pietrantonio",
        position: "Developer",
        linkedinURL: "https://www.linkedin.com/in/nathanLP/",
        imageURL: nathanpietrantonio,
      },
      {
        name: 'Phillip Cutter',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/phillip-cutter/',
        imageURL: phillipcutter,
      },
      {
        name: 'Rayan Tighiouart',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/rayantig/',
        imageURL: rayan,
      },
      {
        name: 'Steven Zhou',
        position: 'Developer',
        linkedinURL: 'https://www.linkedin.com/in/stevzhou/',
        imageURL: stevenzhou,
      },
    ],
  },
};

export default members;
